import './js/preloader';
import './js/support-ukraine';
import './js/main_page/main_page';
import './js/toggle-color-theme';
import './js/header-current-page';
import './js/popup-about-book';
import './js/refs';
import './js/modal-auth/modal-window-authFirebase';
import './js/modal-auth/firebaseAuth';
import './js/menu';
import './js/modal-auth/firebaseDatabase';
import './js/open-and-close-modal';
import './js/upBtn';
