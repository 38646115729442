import { getAuth, onAuthStateChanged } from 'firebase/auth';

const homePg = document.querySelector('.nav__link_home');
const shopList = document.querySelector('.nav__link_shopping');
const currentPath = window.top.location.pathname;

if (
  currentPath === '/book-team-project-13/index.html' ||
  currentPath === '/index.html' ||
  currentPath === '/book-team-project-13/' ||
  currentPath === '/'
) {
  homePg.classList.add('current__page');
} else if (currentPath === '/shopping-list.html') {
  shopList.classList.add('current__page');
  homePg.classList.remove('current__page');
}

const auth = getAuth();

onAuthStateChanged(auth, user => {
  if (!user) {
    shopList.classList.add('is-hidden');
    homePg.classList.add('is-hidden');
  } else {
    shopList.classList.remove('is-hidden');
    homePg.classList.remove('is-hidden');
  }
});
